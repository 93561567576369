import React from "react";
import { Link } from "react-router-dom";
import momentService from "../../../helpers/momentHelper";
import { LARGEDEVICE, SMARTPHONE } from "../../../variable";

const columns = (row, detail, query, profile, device) => {
  const sumbitDetail = (row, action) => {
    detail(row, action);
  };
  const share = (row, action) => {
    detail(row, action);
  };

  const dekstop = [
    {
      name: "",
      cell: (row, index) => (query.page - 1) * query.pageSize + index + 1,
      width: device === SMARTPHONE ? "10%" : "60px",
    },
    {
      name: "Tanggal dibuat",
      cell: (row) => momentService(row.createdAt),
    },
    {
      name: "Status",
      cell: (row) => row.status,
    },
    {
      name: "Nominal Transaksi Koin",
      cell: (row) => row.nominal,
    },
    {
      name: "Koin",
      cell: (row) => row.coin,
    },
    {
      name: "note",
      cell: (row) => row?.note,
    },
    {
      name: "Transaksi",
      cell: (row) => (
        <div onClick={() => sumbitDetail(row, "goto")}>{row?.transaction}</div>
      ),
    },
  ];

  return device === LARGEDEVICE ? dekstop : dekstop;
};

export default columns;
