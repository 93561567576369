import axiosInstance from "../helpers/axiosHelper";

const path = "/api/v1/customer/payment";

export const midtransPost = (pricingid, type, name) => {
  return axiosInstance.post(`${path}`, {
    pricingid,
    type,
    name,
  });
};
