import react from "react";
import NavbarComp from "../components/NavbarComp";

const FaqPage = () => {
  const searchCB = () => {};
  return (
    <>
      <NavbarComp enableSearch={false} searchCB={searchCB}></NavbarComp>
      <div style={{ marginTop: "60px", padding: "10px" }}>
        <h2 className="mt-5 pt-5">Kebijakan Baru</h2>
        <p>
          Hi pengguna aplikasi website undangan idiinvitebook terimakasih telah
          memilih kami sebagai platform untuk membuat website undangan. setelah
          2 tahun berjalan tentunya sudah banyak manfaat yang pengguna dapatkan
          dari aplikasi ini tentu kami sangat senang dengan pencapaian kawan-
          kawan pengguna yang bisa membuat ratusan undangan website sekaligus
          dan menjadi sumber pemasukan baru.
        </p>
        <p>
          Kami di idinvitebook selalu berupaya memberikan harga yang sewajar
          mungkin untuk pengguna bisa nikmati, namun dikarenakan trafik web kami
          yang tinggi dan untuk menunjang biaya oprasional mulai dari tanggal 5
          januari 2025 plugins tidak kami gratiskan lagi. setiap penggunaan
          plugin kirim instan per 5 januari 2025 teman-teman akan dikenakan
          harga 10 ribu / undangan
        </p>
        <p>
          Biaya ini tidak berlaku bagi undangan yang dibuat sebelum tanggal 5
          januari 2025.
        </p>
        <p className="mt-5">Atas permaklumannya kami ucapkan Terimakasih</p>
      </div>
    </>
  );
};

export default FaqPage;
