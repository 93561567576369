import axiosInstance from "../helpers/axiosHelper";
const path = "/api/v1/customer/transaction";

export const getTransactions = (pageSize, page) => {
  let url = `${path}?page=${page}&pageSize=${pageSize}`;
  return axiosInstance.get(url);
};

export const getTransactionDetail = (role, id) => {
  return axiosInstance.get(`/api/v1/${role}/transaction/${id}`);
};
export const updateTransaction = (data) => {
  return axiosInstance.put(`${path}`, data);
};
