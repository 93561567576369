import { Alert, Col, Container, Row } from "react-bootstrap";
import NavbarComp from "../components/NavbarComp";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ContactCSComp from "../components/ContactCSComp";
import { midtransPost } from "../services/midtransServices";
import { getPricing, createPricing } from "../services/pricingServices";
import { updateTransaction } from "../services/transactionServices";
import { getCoin } from "../services/coinServices";
const PricingPage = () => {
  const [totalCoin, setTotalCoin] = useState(0);
  const [orderId, setOrderId] = useState("123");
  const [grossAmount, setGrossAmount] = useState("");

  const handlePayment = async (pricingid) => {
    try {
      let type = "activation";
      let name = "";
      const response = await midtransPost(pricingid, type);
      console.log("pricingdd", response);
      if (response?.data?.transactionToken) {
        let transaction_id = response?.data?.transaction_id;
        const token = response?.data?.transactionToken;
        // Ambil token dari response
        window.snap.pay(token, {
          onSuccess: function (result) {
            console.log("Payment Success:", result);
            // Lakukan sesuatu setelah pembayaran sukses
            updateTransaction({
              transaction_id: transaction_id,
              status: "paid",
              midtrans: result?.transaction_id,
            }).then((res) => {
              alert("pembayaran berhasil");
              console.log("paid", res);
            });
          },
          onPending: function (result) {
            console.log("Payment Pending:", result);
            updateTransaction({
              transaction_id: transaction_id,
              status: "pending",
              midtrans: result?.transaction_id,
            }).then((res) => {
              alert("pembayaran pending");
              console.log("pending", res);
            });
          },
          onError: function (result) {
            console.log("Payment Error:", result);
            updateTransaction({
              transaction_id: transaction_id,
              status: "failed",
              midtrans: result?.transaction_id,
            }).then((res) => {
              alert("pembayaran failed");
              console.log("failed", res);
            });
          },
          onClose: function () {
            console.log("Payment Window Closed");
          },
        });
      }
    } catch (error) {
      console.error("Payment Error:", error);
    }
  };
  const dispatch = useDispatch();

  const [listPricing, setListPricing] = useState([]);

  const fetchPricing = async () => {
    getPricing("starter")
      .then((res) => {
        setListPricing(res?.data?.pricing);
      })
      .catch((err) => {});
  };

  const fetchCoin = async () => {
    let res = await getCoin();
    if (res) {
      console.log("rescoin", res);
      setTotalCoin(res?.data?.coin);
    }
  };
  useEffect(() => {
    fetchPricing();
    fetchCoin();
  }, []);

  return (
    <div>
      <NavbarComp></NavbarComp>
      <Container>
        <Row className="mt-5">
          <Col md={12}>
            <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
              <h3 class="display-6">Tentukan Masa Aktif</h3>
            </div>
            <Container>
              <Row>
                {listPricing.map((pricing) => {
                  return (
                    <Col>
                      <div class="card-deck mb-3 text-center">
                        <div class="card mb-4 box-shadow">
                          <div class="card-header">
                            <h4 class="my-0 font-weight-normal">
                              {pricing.name}
                            </h4>
                          </div>
                          <div class="card-body">
                            <h1 class="card-title pricing-card-title">
                              {pricing.price}{" "}
                            </h1>
                            <ul class="list-unstyled mt-3 mb-4">
                              <li>Unlimeted Undangan</li>
                              <li>{pricing.coin} Koin</li>
                            </ul>
                            <button
                              type="button"
                              class="btn btn-md btn-primary"
                              onClick={() => handlePayment(pricing?._id)}
                            >
                              Pembayaran
                            </button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <ContactCSComp></ContactCSComp>
    </div>
  );
};

export default PricingPage;
