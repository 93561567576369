import { Col, Container, Row } from "react-bootstrap";
import NavbarComp from "../components/NavbarComp";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import ContactCSComp from "../components/ContactCSComp";
import { getCoinPricing } from "../services/coinServices";
import { updateTransaction } from "../services/transactionServices";
import { midtransPost } from "../services/midtransServices";
const CoinPage = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [listPricing, setListPricing] = useState([]);
  const [orderId, setOrderId] = useState("123");
  const [grossAmount, setGrossAmount] = useState("");

  const handlePayment = async (pricingid, name) => {
    try {
      let type = "coin";
      const response = await midtransPost(pricingid, type, name);
      console.log("pricingdd", response);
      if (response?.data?.transactionToken) {
        let transaction_id = response?.data?.transaction_id;
        const token = response?.data?.transactionToken;
        // Ambil token dari response
        window.snap.pay(token, {
          onSuccess: function (result) {
            console.log("Payment Success:", result);
            // Lakukan sesuatu setelah pembayaran sukses
            updateTransaction({
              transaction_id: transaction_id,
              status: "paid",
              midtrans: result?.transaction_id,
            }).then((res) => {
              alert("pembayaran berhasil");
              console.log("paid", res);
            });
          },
          onPending: function (result) {
            console.log("Payment Pending:", result);
            updateTransaction({
              transaction_id: transaction_id,
              status: "pending",
              midtrans: result?.transaction_id,
            }).then((res) => {
              alert("pembayaran pending");
              console.log("pending", res);
            });
          },
          onError: function (result) {
            console.log("Payment Error:", result);
            updateTransaction({
              transaction_id: transaction_id,
              status: "failed",
              midtrans: result?.transaction_id,
            }).then((res) => {
              alert("pembayaran failed");
              console.log("failed", res);
            });
          },
          onClose: function () {
            console.log("Payment Window Closed");
          },
        });
      }
    } catch (error) {
      console.error("Payment Error:", error);
    }
  };
  const dispatch = useDispatch();
  const [detailProfile, setDetailProfle] = useState();
  const [breadcrumbData, setBreadCrumbData] = useState({
    root: "Home",
    root_link: "/",
    menu: "Profile",
    menu_link: "/profile",
    current: "",
  });

  const fetchPricing = async () => {
    let res = await getCoinPricing();
    console.log("pricings", res);
    if (res) {
      setListPricing(res?.data);
    }
  };

  useEffect(() => {
    fetchPricing();
  }, []);
  return (
    <div>
      <NavbarComp></NavbarComp>
      <Container>
        <Row className="mt-5">
          <Col md={12}>
            <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
              <h3 class="display-6">Tambah Koin</h3>
              <p class="lead">Beli koin untuk dapat mengaktifkan plugins</p>
            </div>
            <Container>
              <Row>
                {listPricing.map((pricing) => {
                  return (
                    <Col>
                      <div class="card-deck mb-3 ">
                        <div class="card mb-4 box-shadow">
                          <div class="card-header">
                            <h4 class="my-0 font-weight-normal text-center">
                              {pricing.name}
                            </h4>
                          </div>
                          <div class="card-body">
                            <h3 class="card-title pricing-card-title text-center">
                              {pricing.price}{" "}
                            </h3>
                            <p className="text-center">
                              Dengan menggunakan paket ini kamu dapat{" "}
                              <b> {pricing.coin} </b> koin
                            </p>

                            <div className="text-center">
                              <button
                                type="button"
                                class="btn btn-md btn-primary "
                                onClick={() =>
                                  handlePayment(pricing?._id, pricing.name)
                                }
                              >
                                Pembayaran
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <ContactCSComp></ContactCSComp>
    </div>
  );
};

export default CoinPage;
