import React, { useEffect, useState } from "react";
import { Button, Modal, ModalDialog } from "react-bootstrap";
import Colors from "../../Colors";
import PluginConfirmation from "../PluginConfirmation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { getCoin } from "../../services/coinServices";
import { useNavigate, useParams } from "react-router-dom";
const PluginView = ({
  landingpage,
  openPluginCB,
  idLandingpage,
  plugins,
  domain,
  makeActive,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [totalCoin, setTotalCoin] = useState(0);
  const [selectedPlugin, setSelectedPlugin] = useState({});
  let navigate = useNavigate();
  const [list, setList] = useState([
    {
      name: "Kirim Instan",
      desc: "Kirim undangan instan",
      icon: "fa-envelope",
      code: "kirim_instan",
      coin: "300",
      color: "#f5f5f5",
      is_active: false,
    },
    {
      name: "Daftar Tamu",
      desc: "Buat daftar tamu",
      icon: "fa-users",
      code: "daftar_tamu",
      coin: "300",
      color: "#f5f5f5",
      is_active: false,
    },
    {
      name: "Buku Tamu digital",
      desc: "Scan daftar tamu",
      icon: "fa-barcode",
      code: "buku_tamu",
      color: "#f5f5f5",
      coin: "3000",
      is_active: false,
    },
    {
      name: "CSS Editor",
      desc: "Buat styling lebih dinamis",
      icon: "fa-code",
      code: "css_editor",
      color: "#f5f5f5",
      coin: "600",
      is_active: false,
    },
  ]);

  useEffect(() => {
    let tempList = [...list];
    let listActive = [];
    if (plugins) {
      for (let b = 0; b < list.length; b++) {
        for (let a = 0; a < plugins.length; a++) {
          if (tempList[b].code === plugins[a]) {
            tempList[b].is_active = true;
            listActive.push(plugins[a]);
          }
        }
      }
      setList(tempList);
    }
  }, [plugins]);

  useEffect(() => {
    fetchCoin();
  }, []);

  const fetchCoin = async () => {
    let res = await getCoin();
    if (res) {
      console.log("rescoin", res);
      setTotalCoin(res?.data?.coin);
    }
  };

  const openDialog = (code) => {
    if (!plugins.includes(code)) {
      setShowDialog(true);
      let filterPlugin = list.find((item) => item.code === code);
      setSelectedPlugin(filterPlugin);
    } else {
      switch (code) {
        case "kirim_instan":
          if (landingpage.slug !== undefined) {
            window.open(`https://${domain}/send/${landingpage.slug}`);
          } else {
            alert("simpan undangan terlebih dulu");
          }
          break;
        case "daftar_tamu":
          if (landingpage.slug !== undefined) {
            window.open(`https://${domain}/guest/${landingpage.slug}`);
          } else {
            alert("simpan undangan terlebih dulu");
          }
          break;
        case "buku_tamu":
          alert("maintanance");
          break;
        case "css_editor":
          if (landingpage.slug !== undefined) {
            makeActive("css_editor");
          } else {
            alert("simpan undangan terlebih dulu");
          }
          // setViewActive("css_editor");
          break;
      }
    }
  };

  const navigateTopUp = () => {
    navigate("/coin");
  };

  return (
    <>
      <Modal
        show={showDialog}
        onHide={() => setShowDialog(false)}
        className="mt-5"
      >
        <Modal.Header>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ flex: 1 }}>
              Aktifkan Plugin {selectedPlugin?.name}
            </div>
            <div
              style={{ width: "50px", marginRight: "-2%" }}
              onClick={() => {
                setShowDialog(false);
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                style={{ fontSize: "27px", cursor: "pointer" }}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <PluginConfirmation
            plugin={selectedPlugin}
            totalCoin={totalCoin}
            activePluginCB={openPluginCB}
          ></PluginConfirmation>
        </Modal.Body>
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px",
          background: "red",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "space-around",
          }}
        >
          <div style={{ fontSize: "14px" }}>Koin Saat ini: {totalCoin}</div>
          <div style={{ float: "right", alignSelf: "end" }}>
            <Button
              className="btn btn-primary btn-sm"
              onClick={() => navigateTopUp()}
            >
              Top up
            </Button>
          </div>
        </div>
      </div>
      <div style={{ paddingLeft: "5px" }}>
        {idLandingpage ? (
          <>
            {list.map((l) => {
              return (
                <div
                  style={{
                    width: "100%",
                    borderBottom: `solid 1px ${Colors.dark}`,
                    minHeight: "55px",
                    color: "#fff",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div
                        style={{ flex: 1 }}
                        onClick={() => openDialog(l.code)}
                      >
                        <div style={{ padding: "5px", paddingLeft: "12px" }}>
                          <div style={{ display: "flex" }}>
                            <div style={{ width: "160px" }}>
                              <h6 style={{ color: "#fff", margin: "0px" }}>
                                {l.name}
                              </h6>
                              <small>{l.desc}</small>
                              <div>
                                {!plugins.includes(l.code) ? (
                                  <small>
                                    <img
                                      src="https://file.idinvitebook.com/1734144135242-coin.png.png"
                                      width="20px"
                                      style={{ marginTop: "-4px" }}
                                    />
                                    {l?.coin}{" "}
                                  </small>
                                ) : (
                                  <div
                                    style={{
                                      padding: "5px",
                                      color: "#fff",
                                      background: "#F3B773",
                                      fontSize: "10px",
                                      textAlign: "center",
                                      width: "70px",
                                      borderRadius: "17px",
                                    }}
                                  >
                                    digunakan
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "30px",
                                textAlign: "center",
                                fontSize: "22px",
                                color: l.color,
                              }}
                            >
                              <i className={`fa ${l.icon}`}></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <p>Untuk menggunakan plugin simpan undangan terlebih dulu</p>
          </>
        )}
      </div>
    </>
  );
};

export default PluginView;
