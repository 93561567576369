import axiosInstance from "../helpers/axiosHelper";

export const getCoin = () => {
  return axiosInstance.get(`/api/v1/customer/coin`);
};

export const getCoinPricing = () => {
  return axiosInstance.get(`/api/v1/customer/coin/pricing`);
};
export const updateCoinServices = (data) => {
  return axiosInstance.put(`/api/v1/admin/coin`, data);
};
