import axiosInstance from "../helpers/axiosHelper";

export const getReportBestMitraServices = (limit) => {
  return axiosInstance.get(`/api/v1/admin/report/best-mitra?limit=${limit}`);
};

export const getReportMitraCoin = (pageSize, page) => {
  return axiosInstance.get(
    `/api/v1/admin/report/mitra-coin?pageSize=${pageSize}&page=${page}`
  );
};

export const getReportMitraCoinPerApp = (pageSize, page, app) => {
  return axiosInstance.get(
    `/api/v1/admin/report/mitra-coin/${app}?pageSize=${pageSize}&page=${page}`
  );
};
