import React from "react";
import { Button } from "react-bootstrap";
const PluginConfirmation = ({ plugin, totalCoin, activePluginCB }) => {
  return (
    <div className="text-center pt-2 pb-2">
      <h3>Plugin {plugin?.name}</h3>
      {parseInt(totalCoin) >= parseInt(plugin?.coin) ? (
        <>
          <p>
            Kamu akan menggunakan <b> {plugin?.coin} koin</b> untuk mengaktifkan
            plugin ini.
          </p>
          <Button
            className="btn btn-primary mb-5"
            onClick={() => {
              activePluginCB(plugin?.code);
            }}
          >
            Aktifkan
          </Button>
        </>
      ) : (
        <>
          <p>
            jumlah koin belum cukup untuk mengaktifkan plugin {plugin?.name}{" "}
            <a>Tambahkan Koin</a>
          </p>
        </>
      )}

      {/* <p>Kamu telah mengaktifkan plugin {plugin?.name}</p>
      <Button className="btn btn-primary mb-5">Aktifkan</Button> */}
    </div>
  );
};

export default PluginConfirmation;
