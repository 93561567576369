import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTransactionDetail } from "../../services/transactionServices";
import NavbarComp from "../../components/NavbarComp";
const InvoiceDetail = () => {
  let { transactionid } = useParams();
  const [invoice, setInvoice] = useState();
  const getTransactionDetailFunc = async (transactionid) => {
    let res = await getTransactionDetail("admin", transactionid);
    if (res) {
      setInvoice(res?.data);
      console.log("res", res);
    }
  };
  useEffect(() => {
    getTransactionDetailFunc(transactionid);
  }, [transactionid]);

  const searchCB = () => {};
  return (
    <>
      <NavbarComp enableSearch={false} searchCB={searchCB}></NavbarComp>
      <div className="invoice-box mt-5 pt-4">
        <table cellpadding="0" cellspacing="0">
          <tr className="top">
            <td colspan="2">
              <table>
                <tr>
                  <td className="title">
                    <img
                      alt=""
                      src=""
                      style={{ width: "100%", maxWidth: "300px" }}
                    />
                  </td>

                  <td>
                    Status: {invoice?.status}
                    <br></br>
                    Midtrans #: {invoice?.midtrans}
                    <br></br>
                    Created: {invoice?.createdAt}
                    <br></br>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr className="information">
            <td colspan="2">
              <table>
                <tr>
                  <td>
                    Sparksuite, Inc.
                    <br></br>
                    12345 Sunny Road
                    <br></br>
                    Sunnyville, CA 12345
                  </td>

                  <td>
                    Acme Corp.
                    <br></br>
                    John Doe
                    <br></br>
                    john@example.com
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr className="heading">
            <td>Item</td>
            <td>Price</td>
          </tr>
          <tr className="item">
            <td>{invoice?.packet_name}</td>
            <td>{invoice?.nominal}</td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default InvoiceDetail;
