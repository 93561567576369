import moment from "moment";

const checkActiveDate = (app_id, app_active) => {
  let y1 = moment(new Date()).year();
  let m1 = moment(new Date()).month();
  let d1 = moment(new Date()).date();
  if (app_active) {
    let y2 = moment(app_active).year();
    let m2 = moment(app_active).month();
    let d2 = moment(app_active).date();
    let a = moment([y2, m2, d2]);
    let b = moment([y1, m1, d1]);
    let dif = a.diff(b, "days");
    if (dif < 0) {
      if (app_id !== "63cd0c7254920f655d6052fc") {
        if (window.location.href != "https://app.idinvitebook.com/pricing") {
          window.location.replace("https://app.idinvitebook.com/pricing");
        }
      }
    }
    return dif;
  } else {
    return "-";
  }
};

export { checkActiveDate };
