import React, { useEffect, useState } from "react";
import { getReportBestMitraServices } from "../../services/reportServices";
import { Link } from "react-router-dom";

const ReportPage = () => {
  const [listBestMitra, setListBestMitra] = useState([]);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const handleResize = () => {
    let toolbarHeight = 200;
    setViewportHeight(window.innerHeight - toolbarHeight);
  };

  useEffect(() => {
    // Set initial viewport height
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getBestMitra = async () => {
    let limit = 300;
    let res = await getReportBestMitraServices(limit);
    if (res) {
      console.log("best", res?.data);
      setListBestMitra(res?.data?.data);
    }
  };

  useEffect(() => {
    getBestMitra();
  }, []);

  return (
    <>
      Result
      <table
        style={{
          Width: "100%",
          background: "#f5f5f5",
          color: "#333",
          fontSize: "12px",
          height: viewportHeight + `px`,
          overflow: "scroll",
        }}
      >
        <tr>
          <td style={{ width: "25px" }}>No</td>
          <td>Email</td>
          <td>Undangan</td>
        </tr>
        {listBestMitra?.map((r, index) => {
          return (
            <tr>
              <td style={{ width: "25px" }}>{index + 1}</td>
              <td>
                <Link to={`/invitations/${r?.appId}`}>{r?.email}</Link>
              </td>
              <td>{r?.landingPageCount}</td>
            </tr>
          );
        })}
      </table>
    </>
  );
};
export default ReportPage;
